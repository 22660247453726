// Components
import { createPortal } from 'react-dom'
import { Box, Drawer, IconButton } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { AcIcon, AcTypography } from '@components'
import { ICONS } from '@constants'
import { useIsMd, useStore } from '@hooks/index'

import styles from './ac-drawer.module.scss'

interface IAcDrawer {
  withToolbar?: boolean
}

export const AcDrawer = observer(({ withToolbar = false }: IAcDrawer) => {
  const { drawer } = useStore()
  const isMd = useIsMd()
  const drawerRoot = document.getElementById('drawer-root')

  return createPortal(
    <Drawer
      onClose={() => drawer.close()}
      ModalProps={{ onBackdropClick: () => drawer.close() }}
      PaperProps={{
        id: 'ac-drawer',
        sx: {
          width: isMd ? 491 : '100vw',
          boxSizing: 'border-box',
          height: '100vh',
          overflowY: 'auto',
          paddingTop: isMd
            ? withToolbar
              ? '80px'
              : 0
            : withToolbar
            ? '64px'
            : 0,
        },
      }}
      anchor="right"
      open={drawer.is_open}>
      <Box p={3}>
        <Box
          component={'header'}
          position="sticky"
          top={'24px'}
          bgcolor="white"
          className={styles['header']}
          id="drawer-header">
          <AcTypography
            weight="bold"
            size="l"
            className={styles['header__title']}>
            {drawer.get_title}
          </AcTypography>
          <IconButton
            onClick={() => drawer.close()}
            color="primary">
            <AcIcon icon={ICONS.CLOSE} />
          </IconButton>
        </Box>
        <Box>{drawer.get_content}</Box>
      </Box>
    </Drawer>,
    drawerRoot as HTMLElement,
    drawer.get_title,
  )
})
