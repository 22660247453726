import { action, computed, makeAutoObservable } from 'mobx'

import { KEYS, LABELS, STORE_KEYS } from '@constants'
import { Store } from '@stores'
import { ISingleContentTerm } from '@typings'

export class TermsStore {
  store: Store
  loading: boolean
  general_terms: ISingleContentTerm[] | null
  page_terms: ISingleContentTerm[] | null
  error: null | string
  searchValue: string | null

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.loading = false
    this.general_terms = null
    this.page_terms = null
    this.error = null
    this.searchValue = null
  }

  @computed
  get current_general_terms(): TermsStore['general_terms'] {
    return this.general_terms
  }

  @computed
  get current_page_terms(): TermsStore['page_terms'] {
    return this.page_terms
  }

  @computed
  get all_current_terms(): ISingleContentTerm[] {
    let terms: ISingleContentTerm[] = []

    if (this.current_page_terms?.length) {
      terms = [...this.current_page_terms]
    }

    if (this.current_general_terms?.length) {
      terms = [...terms, ...this.current_general_terms]
    }
    return terms
  }

  @computed
  get current_is_loading(): TermsStore['loading'] {
    return this.loading
  }

  @computed
  get current_api_error(): TermsStore['error'] {
    return this.error
  }

  @action
  setSearchTerm = (term: string | null) => {
    this.store.set(STORE_KEYS.TERMS, KEYS.SEARCH_VALUE, term)
  }

  @action
  getGeneralTerms = async () => {
    if (this.current_general_terms) return
    this.store.set(STORE_KEYS.TERMS, KEYS.LOADING, true)
    try {
      const terms = await this.store.services.terms.getAll()
      this.store.set(STORE_KEYS.TERMS, KEYS.GENERAL_TERMS, terms)
    } catch (e: unknown) {
      console.error(e)
      let error = ''
      if (typeof e === 'string') {
        error = e
      } else if (e instanceof Error) {
        error = e.message
      }
      this.store.set(STORE_KEYS.TERMS, KEYS.ERROR, error || LABELS.TERMS_ERROR)
    } finally {
      this.store.set(STORE_KEYS.TERMS, KEYS.LOADING, false)
    }
  }

  @action
  setGeneralTerms = (payload: ISingleContentTerm[]) => {
    this.store.set(STORE_KEYS.TERMS, KEYS.GENERAL_TERMS, payload)
  }

  // Current page terms are set by the audience store on the audience.getSingle() method
  @action
  setCurrentPageTerms = (payload: ISingleContentTerm[]) => {
    this.store.set(STORE_KEYS.TERMS, KEYS.PAGE_TERMS, payload)
  }

  @action
  clearGeneralTerms = () => {
    this.store.set(STORE_KEYS.TERMS, KEYS.GENERAL_TERMS, null)
  }

  @action
  clearCurrentPageTerms = () => {
    this.store.set(STORE_KEYS.TERMS, KEYS.PAGE_TERMS, null)
  }
}
