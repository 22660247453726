import { ButtonBase, Container } from '@mui/material'

import { AcIcon, AcTypography } from '@components'
import { ICONS } from '@constants'
import { ISingleAudience } from '@typings'

interface IContentpageLinkgrid extends Partial<ISingleAudience> {
  onClick: () => void
}

export const ContentpageLinkgridLink = ({
  name,
  description,
  onClick,
}: IContentpageLinkgrid) => {
  return (
    <ButtonBase
      onClick={onClick}
      sx={({ palette }) => ({
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        alignItems: 'flex-start',
        padding: '1rem',
        width: '100%',
        height: '100%',
        justifyContent: 'flex-start',
        border: `1px solid ${palette.grey[300]}`,
        marginBottom: '1px',
        borderRadius: '.25rem',
        transition: 'border-color 0.25s ease',
        '&:hover': {
          borderColor: palette.primary.light,
          borderBottom: `2px solid ${palette.primary.light}`,
          marginBottom: 0,
        },
      })}>
      <Container
        disableGutters
        sx={({ palette }) => ({
          display: 'flex',
          justifyContent: 'space-between',
          color: palette.primary.light,
        })}>
        <AcTypography
          size="l"
          weight="bold">
          {name && name}
        </AcTypography>
        <AcIcon icon={ICONS.ARROW_FORWARD} />
      </Container>
      <AcTypography>{description}</AcTypography>
    </ButtonBase>
  )
}
