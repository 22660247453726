import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { usePiwik } from '@hooks/use-piwik'

import { ROUTES } from '@constants'

export const AcRouter = observer(() => {
  const router = createBrowserRouter(ROUTES)

  const piwik = usePiwik()
  piwik.init()

  return <RouterProvider router={router} />
})
