import { forwardRef } from 'react'

import { ISingleContentBlockRichText } from '@typings'

import styles from './ac-richtext.module.scss'

export const AcRichText = forwardRef(
  (
    { content }: ISingleContentBlockRichText,
    ref: React.ForwardedRef<HTMLElement>,
  ) => {
    if (!content) return null

    return (
      <article
        ref={ref}
        className={styles['ac-richtext']}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    )
  },
)
