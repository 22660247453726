import {
  AppBar,
  Badge,
  Box,
  ClickAwayListener,
  Collapse,
  Container,
  Toolbar,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { NavLink, matchPath, useLocation, useNavigate } from 'react-router-dom'

import { AcHeaderButton, AcTypography } from '@components'
import { ICONS, LABELS, ROUTE_PATHS } from '@constants'
import { useIsMd } from '@hooks/use-isMd'
import { usePiwik } from '@hooks/use-piwik'
import { useStore } from '@hooks/use-store'

import {
  AcAppHeaderFilters,
  AcAppHeaderTrackSelect,
  AcDrawerContact,
  AcDrawerTerms,
} from '../index.features.components'

import styles from './ac-app-header.module.scss'

export const AcAppHeader = observer(() => {
  const navigate = useNavigate()
  const { trackContactDrawer, trackTermsDrawer } = usePiwik()
  const location = useLocation()
  const { pathname } = useLocation()
  const [showSelectable, setShowSelectable] = useState(false)
  const { drawer, tracks } = useStore()
  const isMd = useIsMd()
  const currentDrawerTitle = drawer.title

  const showTrackFilter = useMemo(() => {
    return pathname === ROUTE_PATHS.TRACKS
  }, [pathname])

  const showTermList = useMemo(
    () => pathname !== ROUTE_PATHS.HOME && pathname !== ROUTE_PATHS.TRACKS,
    [pathname],
  )

  const handleDrawerClick = () => {
    if (showSelectable) setShowSelectable(false)
    drawer.toggleDrawer(LABELS.TERMSLIST, <AcDrawerTerms />)
    trackTermsDrawer()
  }

  const getSelectedTracks = useMemo(() => {
    if (showTrackFilter) {
      return tracks.current_selected_track_id !== null ? '1' : null
    }
    const getNumber =
      tracks.current_selected_track_id && tracks.current_selected_milestone_id
        ? '2'
        : '1'
    const match = matchPath(ROUTE_PATHS.CONTENT_PAGE, pathname)
    return match !== null ? getNumber : null
  }, [
    pathname,
    showTrackFilter,
    tracks.current_selected_milestone_id,
    tracks.current_selected_track_id,
  ])

  const handleContactClick = () => {
    if (showSelectable) setShowSelectable(false)
    drawer.toggleDrawer(LABELS.CONTACT, <AcDrawerContact />)
    trackContactDrawer()
  }

  const handleShowSelectable = useCallback(() => {
    if (showSelectable) setShowSelectable(false)
  }, [showSelectable])

  useEffect(() => {
    handleShowSelectable()
    return () => {
      if (drawer.open) {
        drawer.close()
      }
    }
  }, [pathname])

  return (
    <AppBar
      id="navbar"
      component={'nav'}
      position="sticky"
      sx={({ palette }) => ({
        background: palette.common.white,
        alignContent: 'center',
        zIndex: theme => theme.zIndex.drawer + 1,
      })}>
      {isMd && (
        <Toolbar>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              alignItems: 'center',
            }}>
            {showTrackFilter ? (
              <>
                <Box sx={{ marginRight: { xs: 0, md: 1.5 } }}>
                  <AcHeaderButton
                    icon={ICONS.ARROW_BACK}
                    label={LABELS.BACK}
                    onClick={() => navigate(-1)}
                  />
                </Box>
                <AcAppHeaderTrackSelect />
              </>
            ) : (
              <>
                <Box sx={{ marginRight: { xs: 0, md: 1.5 } }}>
                  <AcHeaderButton
                    icon={ICONS.HOME}
                    label={LABELS.HOME}
                    onClick={() => navigate(ROUTE_PATHS.HOME)}
                  />
                </Box>
                <AcAppHeaderFilters />
              </>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              justifyContent: 'flex-end',
              alignItems: 'center',
              flex: '1 0 auto',
            }}>
            {showTermList && (
              <AcHeaderButton
                icon={ICONS.LIST}
                label={LABELS.TERMSLIST}
                onClick={handleDrawerClick}
                active={drawer.get_title === LABELS.TERMSLIST}
              />
            )}
            <NavLink
              title={LABELS.ROADMAP}
              className={styles['button-link']}
              to={ROUTE_PATHS.TRACKS}>
              {({ isActive }) => (
                <AcHeaderButton
                  icon={ICONS.ROADMAP}
                  label={LABELS.ROADMAP}
                  active={isActive}
                  isLink
                />
              )}
            </NavLink>
            <AcHeaderButton
              icon={ICONS.HELP}
              label={LABELS.CONTACT}
              active={drawer.get_title === LABELS.CONTACT}
              onClick={handleContactClick}
            />
          </Box>
        </Toolbar>
      )}
      {!isMd && (
        <ClickAwayListener onClickAway={handleShowSelectable}>
          <Toolbar
            role="presentation"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Collapse
              in={showSelectable}
              sx={{ width: '100%' }}>
              <Container
                disableGutters
                sx={{ flex: 1, width: '100%' }}>
                <Box
                  sx={({ palette }) => ({
                    padding: '16px 24px 8px',
                    color: palette.primary.main,
                  })}>
                  <AcTypography
                    size="s"
                    weight="bold">
                    {showTrackFilter ? LABELS.YOUR_FILTER : LABELS.YOU_ARE_HERE}
                  </AcTypography>
                </Box>
                <Box
                  sx={({ palette }) => ({
                    color: palette.grey['400'],
                  })}>
                  {showTrackFilter ? (
                    <AcAppHeaderTrackSelect />
                  ) : (
                    <AcAppHeaderFilters />
                  )}
                </Box>
              </Container>
            </Collapse>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}>
              <AcHeaderButton
                icon={ICONS.HOME}
                label={LABELS.HOME}
                onClick={() => navigate(ROUTE_PATHS.HOME)}
              />
              <NavLink
                title={LABELS.ROADMAP}
                className={styles['button-link']}
                to={ROUTE_PATHS.TRACKS}>
                {({ isActive }) => (
                  <AcHeaderButton
                    icon={ICONS.ROADMAP}
                    label={LABELS.ROADMAP}
                    active={isActive}
                    isLink
                  />
                )}
              </NavLink>
              {showTermList && (
                <AcHeaderButton
                  icon={ICONS.LIST}
                  label={LABELS.TERMSLIST}
                  active={drawer.get_title === LABELS.TERMSLIST}
                  onClick={handleDrawerClick}
                />
              )}
              <AcHeaderButton
                icon={ICONS.HELP}
                label={LABELS.CONTACT}
                active={drawer.get_title === LABELS.CONTACT}
                onClick={handleContactClick}
              />
              <Badge
                color="info"
                badgeContent={getSelectedTracks}
                overlap="circular">
                <AcHeaderButton
                  active={showSelectable}
                  icon={showSelectable ? ICONS.CHEVRON_UP : ICONS.CHEVRON_DOWN}
                  label={LABELS.FILTERS}
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    setShowSelectable(!showSelectable)
                  }}
                />
              </Badge>
            </Box>
          </Toolbar>
        </ClickAwayListener>
      )}
    </AppBar>
  )
})
