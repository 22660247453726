import { usePiwik } from '@hooks/use-piwik'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const AcScrollTop = () => {
  const { pathname } = useLocation()
  const { trackPageView } = usePiwik()

  const getTitle = () => {
    switch (pathname) {
      case '/':
        return 'VNG | Routekaart Financiële Zorgen | Home'

      case '/tracks':
        return 'VNG | Routekaart Financiële Zorgen | Routekaart'

      default:
        return `VNG | Routekaart Financiële Zorgen`
    }
  }

  const pageTitle = getTitle()

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = pageTitle
    trackPageView(pageTitle)
  }, [pathname])

  return null
}
