import { createRef, useEffect, useMemo, useState } from 'react'
import {
  Box,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
} from '@mui/material'
import type { ITabs } from '@typings'

// Styles
import { ICONS, LABELS } from '@constants'
import { useIsMd } from '@hooks/use-isMd'
import { useElViewportPosition } from '@hooks/use-viewport-position'

import { AcTabPanel } from '../ac-tab-panel/ac-tab-panel'
import { AcIcon } from '../index.core.components'

export const AcTabs = ({ title, tabs, withSearch, onSearch }: ITabs) => {
  const [currentTab, setCurrentTab] = useState(tabs.length > 1 ? 1 : 0)
  const [searchInput, setSearchInput] = useState<string | null>('')
  const tabsRef = createRef<HTMLDivElement>()
  const isMd = useIsMd()
  const hasHeader = document.getElementById('navbar')

  const handleSearch = (value: string | null) => {
    setSearchInput(value)
    if (onSearch) onSearch(value)
  }

  const a11yProps = (index: number) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    }
  }

  const resetDrawerScrollPosition = () => {
    const drawer = document.getElementById('ac-drawer')
    if (drawer) {
      drawer.scrollTop = 0
    }
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    handleSearch('')
    resetDrawerScrollPosition()
    setCurrentTab(newValue)
  }

  const offset = useElViewportPosition(tabsRef)

  const calculateTopOffset = useMemo(() => {
    if (offset?.top) {
      const minus = hasHeader ? (isMd ? 80 : 64) : 0
      return `${offset.top - minus}px`
    }
  }, [offset.top, hasHeader])

  useEffect(() => {
    return () => handleSearch(null)
  }, [])

  return (
    <>
      <Box
        mt={0}
        mb={0}
        pb={0}
        position={'sticky'}
        top={calculateTopOffset}
        bgcolor="white"
        ref={tabsRef}
        sx={{
          ':after': {
            content: '""',
            position: 'absolute',
            pointerEvents: 'none',
            left: 0,
            bottom: 0,
            background:
              'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255, 255, 255, 1) 100%)',
            right: 0,
            height: '40px',
            width: '100%',
            transform: 'translateY(100%)',
          },
        }}>
        <Tabs
          component="div"
          value={currentTab}
          onChange={handleTabChange}
          sx={{ borderBottom: tabs.length > 1 ? 1 : 0, borderColor: 'divider' }}
          aria-label={title}>
          {tabs.length > 1 &&
            tabs.map((tab, i) => (
              <Tab
                key={i}
                label={tab.title}
                {...a11yProps(i)}
              />
            ))}
        </Tabs>
        {withSearch && onSearch && (
          <TextField
            size="small"
            fullWidth
            sx={{ mt: 2 }}
            placeholder={LABELS.SEARCH}
            value={searchInput}
            onChange={e => handleSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AcIcon icon={ICONS.SEARCH} />
                </InputAdornment>
              ),
              endAdornment: searchInput && (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => handleSearch('')}>
                    <AcIcon icon={ICONS.CLOSE} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      </Box>
      {tabs.map((tab, i) => (
        <AcTabPanel
          key={i}
          title={tab.title}
          index={i}
          content={tab.content}
          currentTab={currentTab}
        />
      ))}
    </>
  )
}
