import { useState, useEffect } from 'react'
import PiwikPro, { PageViews, CustomEvent } from '@piwikpro/react-piwik-pro'
import type {
  ISingleTrack,
  ISingleMilestoneNode,
  ISingleMilestoneAudience,
} from '@typings'

// eslint-disable-next-line
// Docs:                https://developers.piwik.pro/en/latest/data_collection/web/frameworks/Piwik_PRO_Library_for_React.html
// Pageview:            trackPageView(customPageTitle?: string)
// Custom event:        trackEvent(category: string, action: string, name?: string, value?: number)

const SETTINGS = {
  TRACKING_ID: process.env.REACT_APP_PIWIK_TRACKING_ID || '',
  TRACKING_URL: process.env.REACT_APP_PIWIK_TRACKING_URL || '',
}

const CATEGORY = {
  DEFAULT: 'vng_routekaart',
}

const ACTIONS = {
  EXTERNAL_LINKS_DRAWER: 'user_opened_links_drawer',
  TERMS_DRAWER: 'user_opened_terms_drawer',
  CONTACT_DRAWER: 'user_opened_contact_drawer',
  CONTACT_CLICK: 'user_clicked_contact_item',
  DOWNLOADS: 'user_download_file',
  SEARCH_TERM_INPUT: 'user_terms_search_input',
  TRACK_SELECT: 'user_track_select',
  MILESTONE_SELECT: 'user_milestone_select',
  MILESTONE_CLICK: 'user_milestone_click',
  ROLE_CLICK: 'user_role_click',
}

export const usePiwik = () => {
  const [searchTerm, setSearchTerm] = useState<string | null>(null)

  const formatMilestoneEvent = (
    track: ISingleTrack,
    milestone: ISingleMilestoneNode,
  ) => {
    return `${track.name} | ${milestone.name}`
  }

  const init = () => {
    if (SETTINGS.TRACKING_ID && SETTINGS.TRACKING_URL) {
      try {
        PiwikPro.initialize(SETTINGS.TRACKING_ID, SETTINGS.TRACKING_URL)
      } catch (error) {
        console.warn('Could not inintialize Piwik')
      }
    }
    if (!SETTINGS.TRACKING_ID)
      console.warn('Piwik tracking ID could not be read')
    if (!SETTINGS.TRACKING_URL)
      console.warn('Piwik tracking URL could not be read')
  }

  const trackPageView = (url = window.location.href) => {
    PageViews.trackPageView(url)
  }

  const trackExternalLinksDrawer = () => {
    CustomEvent.trackEvent(
      CATEGORY.DEFAULT,
      ACTIONS.EXTERNAL_LINKS_DRAWER,
      'drawer_open',
    )
  }

  const trackTermsDrawer = () => {
    CustomEvent.trackEvent(
      CATEGORY.DEFAULT,
      ACTIONS.TERMS_DRAWER,
      'drawer_open',
    )
  }

  const trackContactDrawer = () => {
    CustomEvent.trackEvent(
      CATEGORY.DEFAULT,
      ACTIONS.CONTACT_DRAWER,
      'drawer_open',
    )
  }

  const trackContactClick = (item: 'phone' | 'email') => {
    CustomEvent.trackEvent(CATEGORY.DEFAULT, ACTIONS.CONTACT_CLICK, item)
  }

  const trackDownLoads = (filename: string) => {
    CustomEvent.trackEvent(CATEGORY.DEFAULT, ACTIONS.DOWNLOADS, filename)
  }

  const trackTermsSearchInput = (input: string | null) => {
    setSearchTerm(input)
  }

  const trackTrackSelect = (track: ISingleTrack | undefined) => {
    if (track?.name) {
      CustomEvent.trackEvent(CATEGORY.DEFAULT, ACTIONS.TRACK_SELECT, track.name)
    }
  }

  const trackMilestoneSelect = (
    track: ISingleTrack,
    milestone: ISingleMilestoneNode,
  ) => {
    const event = formatMilestoneEvent(track, milestone)
    CustomEvent.trackEvent(CATEGORY.DEFAULT, ACTIONS.MILESTONE_SELECT, event)
  }

  const trackMilestoneClick = (
    track: ISingleTrack,
    milestone: ISingleMilestoneNode,
  ) => {
    const event = formatMilestoneEvent(track, milestone)
    CustomEvent.trackEvent(CATEGORY.DEFAULT, ACTIONS.MILESTONE_CLICK, event)
  }

  const trackRoleClick = (audience: ISingleMilestoneAudience) => {
    CustomEvent.trackEvent(CATEGORY.DEFAULT, ACTIONS.ROLE_CLICK, audience.name)
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm) {
        CustomEvent.trackEvent(
          CATEGORY.DEFAULT,
          ACTIONS.SEARCH_TERM_INPUT,
          searchTerm,
        )
      }
    }, 3000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  return {
    init,
    trackPageView,
    trackExternalLinksDrawer,
    trackTermsDrawer,
    trackContactDrawer,
    trackContactClick,
    trackDownLoads,
    trackTermsSearchInput,
    trackTrackSelect,
    trackMilestoneSelect,
    trackMilestoneClick,
    trackRoleClick,
  }
}
