import { useMemo } from 'react'
import { Box } from '@mui/material'
import clsx from 'clsx'

import { ISingleContentBlockEmbed } from '@typings'

import styles from './ac-embed.module.scss'

interface IAcEmbed {
  type: ISingleContentBlockEmbed['type']
  src: ISingleContentBlockEmbed['src']
  title?: string
  width?: number | string
  height?: number | string
  className?: string
}

export const AcEmbed = ({
  type,
  src,
  title,
  width = '100%',
  height = 0,
  className,
}: IAcEmbed) => {
  if (!type) {
    console.warn('You have not specified an embed type')
    return null
  }

  const generateClasses = useMemo(
    () =>
      clsx(
        styles['ac-embed'],
        styles[`ac-embed--${type}`],
        className && className,
      ),
    [type],
  )
  return (
    <Box className={generateClasses}>
      <iframe
        className={styles['ac-embed__iframe']}
        width={width}
        height={height}
        src={src}
        title={title}></iframe>
    </Box>
  )
}
