import React from 'react'
import { CssBaseline, GlobalStyles } from '@mui/material'
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles'
import { render } from 'preact'

import { AcDrawer, AcRouter } from '@components'
import reportWebVitals from '@helpers/report-web-vitals'
import { DEFAULT_THEME } from '@themes'

import 'preact/debug'

const root = document.getElementById('root') as HTMLElement
const globalStyles = (
  <GlobalStyles
    styles={{
      html: {
        height: '100%',
      },
      body: {
        height: '100%',
        maxHeight: '100vh',
        overflowY: 'auto',
        overflowX: 'hidden',
      },
      // '#root': {
      //   height: '100%',
      // },
    }}
  />
)

render(
  <React.StrictMode>
    {globalStyles}
    <CssVarsProvider theme={DEFAULT_THEME}>
      <CssBaseline />
      <AcRouter />
    </CssVarsProvider>
  </React.StrictMode>,
  root,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
