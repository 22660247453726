import { AxiosResponse } from 'axios'

import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'
import { ISingleAudience } from '@typings'

export class AudienceService extends AxiosBaseApi {
  getSingle = (id: ISingleAudience['id']) =>
    this.api
      .get<AxiosResponse<{ data: ISingleAudience }>>(
        ENDPOINTS.AUDIENCE.SINGLE(id),
      )
      .then(response => response.data.data)
}
