import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { Button, ButtonProps } from '@mui/material'

interface IAcSwiperButton extends ButtonProps {
  direction: 'prev' | 'next'
  label: string
  icon?: React.ReactNode
}

export const AcSwiperButton = ({
  direction,
  label,
  ...rest
}: IAcSwiperButton) => {
  return (
    <Button
      {...rest}
      startIcon={
        direction == 'prev' && (
          <ChevronLeft sx={({ palette }) => ({ color: palette.grey[200] })} />
        )
      }
      endIcon={
        direction == 'next' && (
          <ChevronRight sx={({ palette }) => ({ color: palette.grey[200] })} />
        )
      }
      variant="outlined"
      sx={({ palette }) => ({
        fontWeight: 400,
        fontSize: '16px',
        color: palette.common.black,
        backgroundColor: palette.common.white,
        paddingBlock: '8px',
        paddingInline: '16px',
        borderRadius: '25px',
        lineHeight: 1,
        boxShadow: '0px 10px 13px 0px #00000008',
        borderColor: palette.grey['200'],
        '&:hover': {
          backgroundColor: palette.common.white,
        },
      })}>
      {label}
    </Button>
  )
}
