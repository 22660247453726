import { useMemo } from 'react'
import { IconProps } from '@mui/material'
import clsx from 'clsx'

import { ICON_ELEMENTS } from '@constants'

import styles from './ac-icon.module.scss'

interface IAcIcon extends IconProps {
  icon: keyof typeof ICON_ELEMENTS
  size?: 'sm' | 'md' | 'lg' | 'xl'
}

export const AcIcon = ({ icon, size = 'md', color = 'inherit' }: IAcIcon) => {
  const Icon = ICON_ELEMENTS[icon]
  const iconClasses = useMemo(
    () => clsx(styles['ac-icon'], styles[`ac-icon__size-${size}`]),
    [size],
  )

  return (
    <Icon
      className={iconClasses}
      color={color}
      htmlColor={color}
    />
  )
}
