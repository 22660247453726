import {
  AcAlert,
  AcButton,
  AcPageWrapper,
  AcTitle,
  AcTypography,
} from '@components'

export const StylesView = () => {
  return (
    <AcPageWrapper>
      <div>
        <AcTitle level={1}>Example text 1</AcTitle>
        <AcTitle level={2}>Example text 2</AcTitle>
        <AcTypography size="l">I am a big paragraph</AcTypography>
        <AcTypography
          size="l"
          weight="bold">
          I am a big bold paragraph
        </AcTypography>
        <AcTypography>I am a regular paragraph</AcTypography>
        <AcTypography weight="bold">I am a regular bold paragraph</AcTypography>
        <AcTypography size="s">I am a small paragraph</AcTypography>
        <AcTypography
          size="s"
          weight="bold">
          I am a small bold paragraph
        </AcTypography>
        <AcTypography variant="span">I am a span</AcTypography>
        <AcTitle level={2}>Buttons</AcTitle>
        <AcButton
          variant="contained"
          label="Click me"
        />
        <AcButton
          variant="contained"
          label="Disabled"
          disabled
        />
        <AcTitle level={2}>Alerts</AcTitle>
        <AcAlert
          severity="success"
          label="I am success"
        />
        <AcAlert
          severity="warning"
          label="I am warning"
        />
        <AcAlert
          severity="info"
          label="I am info"
        />
        <AcAlert
          severity="error"
          label="I am error"
        />
      </div>
    </AcPageWrapper>
  )
}
