import { useMemo, useState } from 'react'
import { Box, Link } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { AcIcon, AcTabs, AcTypography } from '@components'
import { ICONS, LABELS } from '@constants'
import { useStore } from '@hooks/use-store'
import { ISingleContentLink, ITab } from '@typings'

import styles from './ac-drawer-links.module.scss'

interface LinksResultTabProps {
  links: ISingleContentLink[] | null
  searchValue?: string | null
}

export const AcDrawerLinks = observer(() => {
  const { audiences } = useStore()
  const [searchValue, setSearchValue] = useState('')

  const AcTermsResultTab = ({ links, searchValue }: LinksResultTabProps) => {
    const searchResults: LinksResultTabProps['links'] = useMemo(() => {
      if (!links || !links.length) return null
      if (!searchValue) return links
      const regex = new RegExp(searchValue, 'gi')
      if (!searchValue || searchValue === '') return links
      const foundInTitle = links?.filter(link =>
        link.name.toLowerCase().includes(searchValue.toLowerCase()),
      )

      const foundInContent = links
        .filter(link =>
          link.description.toLowerCase().includes(searchValue.toLowerCase()),
        )
        ?.map(link => ({
          ...link,
          description: link.description.replace(regex, '<strong>$&</strong>'),
        }))

      return [...foundInTitle, ...foundInContent]
    }, [searchValue, links])

    return (
      <Box>
        {searchResults?.length ? (
          <Box
            mt={2}
            display={'flex'}
            flexDirection={'column'}
            gap={3}
            mb={2}>
            {searchResults.map((result, i: number) => {
              return (
                <Link
                  href={result.url}
                  target="_blank"
                  key={i}
                  underline="none"
                  className={styles['result']}>
                  <Box
                    sx={({ palette }) => ({
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: 1,
                      color: palette.primary.light,
                    })}>
                    <AcTypography
                      mb={0}
                      weight="bold"
                      className={styles['result__title']}>
                      {result.name}
                    </AcTypography>
                    <AcIcon
                      icon={ICONS.LINK}
                      size="sm"
                    />
                  </Box>
                  <AcTypography
                    html
                    mb={0}
                    className={styles['result__description']}>
                    {result.description}
                  </AcTypography>
                </Link>
              )
            })}
          </Box>
        ) : (
          <Box className={styles['no-results']}>
            <AcIcon
              icon={ICONS.SEARCH}
              size="xl"
            />
            <AcTypography
              weight="bold"
              className={styles['no-results-label']}>
              {LABELS.NO_RESULTS}
            </AcTypography>
          </Box>
        )}
      </Box>
    )
  }

  const generateTabs = useMemo(() => {
    let tabs: ITab[] = []

    if (audiences.has_links) {
      tabs = [
        {
          index: 2,
          title: LABELS.PAGE_SPECIFIC,
          content: (
            <AcTermsResultTab
              links={audiences.current_links}
              searchValue={searchValue}
            />
          ),
        },
      ]
    }

    return tabs
  }, [audiences.has_links, searchValue])

  return (
    <AcTabs
      withSearch
      onSearch={value => setSearchValue(value as string)}
      ariaTitle={LABELS.MORE_INFO}
      tabs={generateTabs}
    />
  )
})
