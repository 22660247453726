import { Outlet } from 'react-router-dom'

import { AcAppHeader, AcDrawer, AcScrollTop } from '@components'

export const AcDefaultLayout = (): JSX.Element => {
  return (
    <>
      <AcScrollTop />
      <AcAppHeader />
      <Outlet />
      <AcDrawer withToolbar />
    </>
  )
}
