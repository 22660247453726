import { useMemo } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import { observer } from 'mobx-react-lite'

import { useStore } from '@hooks/use-store'
import { ISingleAudience } from '@typings'

import { ContentpageLinkgridLink } from './link/contentpage-linkgrid-link'

export const ContentPageLinkGrid = observer(() => {
  const { milestones, audiences } = useStore()

  const filteredAudiences = useMemo(() => {
    return milestones.current_audiences.filter(
      ({ id }) => id !== audiences.current_id,
    )
  }, [milestones.current_audiences, audiences.current_id])

  const handleLinkClick = (id: ISingleAudience['id']) => {
    if (!id) return
    audiences.getSingle(id)
  }

  if (!filteredAudiences) return

  return (
    <>
      <Grid
        container
        spacing={2}>
        {filteredAudiences.map(singleAudience => (
          <Grid
            xs={12}
            md={6}
            key={singleAudience.id}
            sx={{ width: '100%' }}>
            <ContentpageLinkgridLink
              {...singleAudience}
              onClick={() => handleLinkClick(singleAudience.id)}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
})
