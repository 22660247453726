import { useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'

import { AcErrorBoundary, AcLoader } from '@components'
import { useIsMd } from '@hooks/use-isMd'
import { useStore } from '@hooks/use-store'

import styles from './ac-page-wrapper.module.scss'

interface IAcPageWrapperProps {
  loading?: boolean
  children: React.ReactNode
}

export const AcPageWrapper = ({ loading, children }: IAcPageWrapperProps) => {
  const [hasNavbar, setHasNavbar] = useState(false)
  const { terms } = useStore()
  const isMd = useIsMd()
  const getMainClasses = useMemo(
    () =>
      clsx(
        styles['ac-page-wrapper'],
        hasNavbar && styles['ac-page-wrapper--with-nav'],
        loading && styles['ac-page-wrapper--loading'],
        isMd && styles['ac-page-wrapper--desktop'],
      ),
    [hasNavbar, isMd],
  )

  useEffect(() => {
    const hasNavbar = document.getElementById('navbar')
    if (hasNavbar) setHasNavbar(true)

    terms.getGeneralTerms()
  }, [])

  return (
    <AcErrorBoundary>
      <main className={getMainClasses}>
        {loading ? <AcLoader /> : children}
      </main>
    </AcErrorBoundary>
  )
}
