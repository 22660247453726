import type { ThemeOptions } from '@mui/material'
import { alpha } from '@mui/material/styles'
import type {
  ColorPartial,
  PaletteColor,
} from '@mui/material/styles/createPalette'

// Fonts
import AvenirBold from '../../assets/fonts/Avenir-Heavy.woff2'
import AvenirRoman from '../../assets/fonts/Avenir-Roman.woff2'

import { PALETTE_LIGHT } from './palette.theme'

const colors = {
  ...PALETTE_LIGHT,
  primary: PALETTE_LIGHT?.primary as ColorPartial,
  success: PALETTE_LIGHT?.success as PaletteColor,
  warning: PALETTE_LIGHT?.warning as PaletteColor,
  error: PALETTE_LIGHT?.error as PaletteColor,
  grey: PALETTE_LIGHT?.grey as ColorPartial,
}

export const COMPONENTS: ThemeOptions['components'] = {
  MuiCssBaseline: {
    styleOverrides: `
      @font-face {
        font-family: 'VNG-Bold';
        font-style: normal;
        font-display: swap;
        font-weight: 800;
        src: local('VNG-Bold'), url(${AvenirBold}) format('woff2');
      }
      @font-face {
        font-family: 'VNG-Roman';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: local('VNG-Roman'), url(${AvenirRoman}) format('woff2');
      }
    `,
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        padding: 16,
        ':focus-visible': {
          border: '1px solid',
          borderColor: colors.common?.white,
          outline: '3px solid',
          outlineColor: colors.common?.black,
        },
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        padding: 16,
        minWidth: 'none',
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: colors.primary['300'],
        },
        ':disabled': {
          backgroundColor: alpha(colors.primary['400'] as string, 0.7),
          color: colors.common?.white,
        },
      },
      text: {
        padding: 4,
        margin: 12,
        fontWeight: 400,
        '&:focus': {
          border: 'none',
        },
        '&:focus-visible': {
          border: 'none',
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        width: 'fit-content',
        border: '2px solid',
      },
      standardSuccess: {
        borderColor: colors.success?.main,
        color: colors.success?.main,
      },
      standardWarning: {
        borderColor: colors.warning?.main,
        color: colors.warning?.main,
      },
      standardError: {
        borderColor: colors.error?.main,
        color: colors.error?.main,
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        marginBottom: '0.45em',
      },
      h1: {
        marginBottom: '1rem',
      },
      h2: {
        marginBottom: '1rem',
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        backgroundColor: colors.primary[300],
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        paddingBlock: 8,
        paddingInline: 12,
        '&.Mui-selected': {
          color: colors.primary[300],
        },
        ':focus-visible': {
          border: '2px dashed',
          borderColor: colors.primary[500],
          outline: 'none',
        },
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        minHeight: 80,
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        color: colors.grey[400],
        backgroundColor: colors.common?.white,
        boxShadow: '0px 4px 13px 0px rgba(0, 0, 0, 0.12)',
        padding: 16,
        fontSize: 14,
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        ':hover': {
          color: colors.common?.black,
        },
        ':focus-visible': {
          border: '1px solid',
          borderColor: colors.common?.white,
          outline: '3px solid',
          borderRadius: '4px',
          outlineColor: colors.common?.black,
          color: colors.common?.black,
        },
      },
    },
  },
}
