import { Alert, Box } from '@mui/material'
import React from 'react'

interface Props {
  children: React.ReactNode
}

interface State {
  hasError: boolean
  error?: Error
}

export class AcErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
    error: undefined,
  }

  public static getDerivedStateFromError(error: Error): State {
    return { error, hasError: true }
  }

  public render(): React.ReactNode {
    const { error, hasError } = this.state
    const { children } = this.props

    if (hasError) {
      return (
        <Box
          sx={{
            padding: '24px',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Alert
            color="error"
            severity="error">
            {
              'Er is een interne fout opgetreden. Probeer de pagina te herladen.'
            }
          </Alert>
        </Box>
      )
    }

    return children
  }
}
