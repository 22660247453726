import { useMemo } from 'react'
import clsx from 'clsx'

import { handleKeyDown } from '@helpers/handle-key-down.helper'

import styles from './ac-sidemenu-item.module.scss'

export interface IAcSidemenuItem {
  label: string
  active?: boolean
  onClick?: () => void
}

export const AcSidemenuItem = ({ label, active, onClick }: IAcSidemenuItem) => {
  const handleClick = () => {
    if (onClick) onClick()
  }

  const sideMenuItemClasses = useMemo(
    () =>
      clsx(
        styles['ac-sidemenu-item'],
        active && styles['ac-sidemenu-item--active'],
      ),
    [active],
  )

  return (
    <button
      type={'button'}
      tabIndex={0}
      title={label}
      onClick={handleClick}
      onKeyDown={e => handleKeyDown(e, handleClick)}
      className={sideMenuItemClasses}>
      <span>{label}</span>
    </button>
  )
}
