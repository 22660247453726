import { AxiosResponse } from 'axios'

import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'
import { ISingleContentTerm } from '@typings'

export class TermsService extends AxiosBaseApi {
  getAll = () =>
    this.api
      .get<AxiosResponse<{ data: ISingleContentTerm[] }>>(ENDPOINTS.TERMS.ALL())
      .then(response => response.data.data)
}
