import { action, computed, makeAutoObservable } from 'mobx'

import { KEYS, STORE_KEYS } from '@constants'
import { Store } from '@stores'

export class DrawerStore {
  store: Store
  open: boolean
  loading: boolean
  title: string
  content: React.ReactNode | null

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.open = false
    this.loading = false
    this.title = ''
    this.content = null
  }

  @computed
  get is_open(): boolean {
    return this.open
  }

  @computed
  get is_closed(): boolean {
    return !this.open
  }

  @computed
  get has_data(): boolean {
    return !!this.content && this.content !== null
  }

  @computed
  get get_title(): string {
    return this.title
  }

  @computed
  get get_content(): React.ReactNode {
    return this.content
  }

  @action
  toggleDrawer = async (title: string, content: React.ReactNode) => {
    if (!title || !content) return
    if (title === this.get_title) {
      this.close()
      return
    }

    this.setDrawerTitle(title)
    this.setDrawerContent(content)
    this.store.set(STORE_KEYS.DRAWER, KEYS.OPEN, true)
  }

  @action
  close = () => {
    this.store.set(STORE_KEYS.DRAWER, KEYS.OPEN, false)
    setTimeout(() => {
      this.store.set(STORE_KEYS.DRAWER, KEYS.CONTENT, null)
      this.store.set(STORE_KEYS.DRAWER, KEYS.TITLE, '')
    }, 500)
  }

  @action
  setDrawerTitle = (payload: string) => {
    this.store.set(STORE_KEYS.DRAWER, KEYS.TITLE, payload)
  }

  @action
  setDrawerContent = (payload: React.ReactNode) => {
    this.store.set(STORE_KEYS.DRAWER, KEYS.CONTENT, payload)
  }

  @action
  clearDrawerContent = () => {
    this.store.set(STORE_KEYS.DRAWER, KEYS.CONTENT, null)
  }
}
