import { AxiosResponse } from 'axios'

import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'
import { ITracks } from '@typings'

export class TracksService extends AxiosBaseApi {
  getAll = () =>
    this.api
      .get<AxiosResponse<{ data: ITracks }>>(ENDPOINTS.TRACKS.ALL)
      .then(response => response.data.data)
}
