import { useMemo } from 'react'
import { Box } from '@mui/material'
import { AcMilestone } from '@components'
import type { ISingleTrack, ISingleTrackRow } from '@typings'

import styles from './ac-track-row.module.scss'

interface ITrackRow {
  track: ISingleTrack
  currentRow: ISingleTrackRow
  currentRowIndex: number
  previousRow?: ISingleTrackRow
  nextRow?: ISingleTrackRow
  hasPreviousMilestones: boolean
  hasAdditionalMilestones: boolean
  isFirstRow: boolean
  isLastRow: boolean
}

export const AcTrackRow = ({
  track,
  previousRow,
  currentRow,
  currentRowIndex,
  nextRow,
  hasPreviousMilestones,
  hasAdditionalMilestones,
  isFirstRow,
  isLastRow,
}: ITrackRow) => {
  const columns = useMemo(() => {
    const cols = Array.from(Array(currentRow.columns).keys())
    const milestones = cols.map(i => {
      const content = currentRow.milestones.find(
        milestone => milestone.column === i + 1,
      )
      if (content) {
        return {
          column: i + 1,
          content,
        }
      } else {
        return {
          column: i + 1,
        }
      }
    })
    return milestones
  }, [currentRow])

  return (
    <Box
      display={'grid'}
      mt={3}
      mb={3}
      gridTemplateColumns={`repeat(${currentRow.columns}, 1fr)`}>
      {columns.map((milestone, i) => {
        return (
          <AcMilestone
            key={i}
            track={track}
            currentRowIndex={currentRowIndex}
            currentColumn={milestone.column}
            milestone={milestone.content}
            previousRow={previousRow}
            currentRow={currentRow}
            nextRow={nextRow}
            isEmptyMilestone={!milestone.content}
            hasPreviousMilestones={hasPreviousMilestones}
            hasAdditionalMilestones={hasAdditionalMilestones}
            isFirstRow={isFirstRow}
            isLastRow={isLastRow}
          />
        )
      })}
    </Box>
  )
}
