import { Box } from '@mui/material'
import type { ITab } from '@typings'

export const AcTabPanel = ({ content, currentTab, index, ...rest }: ITab) => {
  return (
    <div
      role="tabpanel"
      hidden={currentTab !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...rest}>
      {currentTab === index && (
        <Box
          pt={0}
          pb={6}>
          {content}
        </Box>
      )}
    </div>
  )
}
