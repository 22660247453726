import { experimental_extendTheme as extendTheme } from '@mui/material'

import { BREAKPOINTS } from './breakpoints.theme'
import { COMPONENTS } from './components.theme'
import { PALETTE_LIGHT } from './palette.theme'
import { SHADOWS } from './shadows.theme'
import { TRANSITIONS } from './transitions.theme'
import { TYPOGRAPHY } from './typography.theme'

export const DEFAULT_THEME = extendTheme({
  cssVarPrefix: 'ac',
  breakpoints: BREAKPOINTS,
  components: COMPONENTS,
  colorSchemes: {
    light: {
      palette: PALETTE_LIGHT,
    },
  },
  shadows: SHADOWS,
  transitions: TRANSITIONS,
  typography: TYPOGRAPHY,
})
