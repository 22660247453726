/* eslint-disable max-len */
export const CONTACT_DRAWER = {
  SECTION_ONE_TITLE:
    'Wilt u in contact komen met andere gemeenten of heeft u een vraag aan de VNG?',
  SECTION_ONE_INTRO:
    'Meld u aan op het VNG Forum en kom in contact met ons en uw collega-gemeenten.',
  SECTION_ONE_LIST: [
    "Als u al een account heeft, kunt u meteen <a href='https://forum.vng.nl/do/login/'  target='_blank' rel='noopener, noreferrer'>inloggen</a>.",
    "Heeft u nog geen account? <a href='https://forum.vng.nl/do/aspirantregister' target='_blank' rel='noopener, noreferrer'>Registreer</a> u dan eerst.",
  ],
  SECTION_ONE_OUTRO:
    "Het forum van de Routekaart treft u onder het 'Sociaal Domein'.",
  SECTION_ONE_FOOTER:
    'Iedereen die werkzaam is bij een gemeente kan toegang krijgen tot VNG-fora. Voorwaarde tot toegang is dat u gebruik maakt van het zakelijk e-mailadres van uw gemeente.',
  SECTION_TWO_TITLE: 'Neem contact op met de VNG.',
  SECTION_TWO_INTRO:
    'Het programma Verbinden Schuldendomein van de VNG is bereikbaar op werkdagen van 8.30 tot 17.00 uur:',
  SECTION_TWO_LIST: [
    "Via telefoonnummer <a id='phone-link' href='tel:0703738393' target='_blank' rel='noopener, noreferrer'>070-373 83 93</a>",
    "Of mail naar <a id='email-link' href='mailto:schulden@vng.nl' target='_blank' rel='noopener, noreferrer'>schulden@vng.nl</a> ",
  ],
  SECTION_TWO_OUTRO:
    '(We streven ernaar uw vraag binnen vijf werkdagen te beantwoorden)',
}
