import ArrowBackIcon from '@mui/icons-material/ArrowBackOutlined'
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardOutlined'
import CloseIcon from '@mui/icons-material/Close'
import ChevronUpIcon from '@mui/icons-material/ExpandLessOutlined'
import ChevronDownIcon from '@mui/icons-material/ExpandMoreOutlined'
import ListIcon from '@mui/icons-material/FormatListBulletedOutlined'
import HelpIcon from '@mui/icons-material/HelpOutline'
import HomeIcon from '@mui/icons-material/HomeOutlined'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import RoadMapIcon from '@mui/icons-material/LanOutlined'
import LinkIcon from '@mui/icons-material/OpenInNewOutlined'
import SearchIcon from '@mui/icons-material/SearchOutlined'
import FilterIcon from '@mui/icons-material/TuneOutlined'

export const ICON_ELEMENTS = {
  ARROW_BACK: ArrowBackIcon,
  ARROW_FORWARD: ArrowForwardIcon,
  CHEVRON_DOWN: ChevronDownIcon,
  CHEVRON_UP: ChevronUpIcon,
  FILTER: FilterIcon,
  HOME: HomeIcon,
  LINK: LinkIcon,
  HELP: HelpIcon,
  INFO: InfoIcon,
  LIST: ListIcon,
  SEARCH: SearchIcon,
  ROADMAP: RoadMapIcon,
  CLOSE: CloseIcon,
}

export const ICONS = {
  ARROW_BACK: 'ARROW_BACK',
  ARROW_FORWARD: 'ARROW_FORWARD',
  CHEVRON_DOWN: 'CHEVRON_DOWN',
  CHEVRON_UP: 'CHEVRON_UP',
  FILTER: 'FILTER',
  HOME: 'HOME',
  HELP: 'HELP',
  LINK: 'LINK',
  INFO: 'INFO',
  LIST: 'LIST',
  ROADMAP: 'ROADMAP',
  SEARCH: 'SEARCH',
  CLOSE: 'CLOSE',
} as const
