import { Container } from '@mui/material'

interface IAcContainer {
  maxWidth?: number
  children: React.ReactNode
}

export const AcContainer = ({ maxWidth, children }: IAcContainer) => {
  if (maxWidth) {
    return (
      <Container sx={{ maxWidth: `${maxWidth}px !important` }}>
        {children}
      </Container>
    )
  } else {
    return <Container>{children}</Container>
  }
}
