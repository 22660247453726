import { Box, List, ListItem, Skeleton } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { AcButton, AcDrawerLinks, AcSelect, AcSidemenuItem } from '@components'
import { LABELS } from '@constants'
import { useIsMd } from '@hooks/use-isMd'
import { useStore } from '@hooks/use-store'
import { usePiwik } from '@hooks/use-piwik'
import type { ISingleMilestoneAudience } from '@typings'

export const ContentpageAsideMenu = observer(() => {
  const { milestones, audiences, drawer } = useStore()
  const isMd = useIsMd()
  const { trackRoleClick, trackExternalLinksDrawer } = usePiwik()

  const handleSubMenuClick = (audience: ISingleMilestoneAudience) => {
    trackRoleClick(audience)
    handleSubMenuNavigation(audience.id)
  }

  const handleSubMenuNavigation = (audienceId: number) => {
    audiences.getSingle(audienceId)
  }

  const handleMoreInfo = () => {
    trackExternalLinksDrawer()
    drawer.toggleDrawer(LABELS.MORE_INFO, <AcDrawerLinks />)
  }

  return isMd ? (
    <List
      role={'menu'}
      sx={{ minWidth: 244 }}>
      {milestones.loading && (
        <>
          <Skeleton
            variant="rectangular"
            sx={{ width: 224, height: 50 }}
            animation="wave"
          />
          <Skeleton
            variant="text"
            sx={{ margin: '16px 0' }}
            animation="wave"
          />
          <Skeleton
            variant="text"
            sx={{ margin: '16px 0' }}
            animation="wave"
          />
          <Skeleton
            variant="text"
            sx={{ margin: '16px 0' }}
            animation="wave"
          />
          <Skeleton
            variant="text"
            sx={{ margin: '16px 0 3rem' }}
            animation="wave"
          />
          <Skeleton variant="rectangular">
            <AcButton label="Meer informatie" />
          </Skeleton>
        </>
      )}
      {!milestones.loading &&
        milestones.current_audiences.map(audience => (
          <ListItem
            key={audience.id}
            role={'menuitem'}
            disablePadding>
            <AcSidemenuItem
              active={audience.id === audiences.current_id}
              onClick={() => handleSubMenuClick(audience)}
              label={audience.name}
            />
          </ListItem>
        ))}
      {!milestones.loading && audiences.has_links && (
        <Box sx={{ marginTop: '3rem' }}>
          <AcButton
            label={LABELS.MORE_INFO}
            onClick={handleMoreInfo}
          />
        </Box>
      )}
    </List>
  ) : milestones.loading ? (
    <>
      <Skeleton
        variant="rounded"
        height={56}
        animation="wave"
      />
      <Skeleton variant="rectangular">
        <AcButton label={LABELS.MORE_INFO} />
      </Skeleton>
    </>
  ) : (
    <>
      {!!milestones.current_audiences.length && (
        <AcSelect
          key={audiences.current_id}
          options={milestones.current_audiences}
          labelKey={'name'}
          valueKey={'id'}
          onChangeCallback={value => handleSubMenuNavigation(value as number)}
          selectLabel={LABELS.TABLE_OF_CONTENTS}
          value={audiences.current_id}
        />
      )}
      {!milestones.loading && audiences.has_links && (
        <Box>
          <AcButton
            label={LABELS.MORE_INFO}
            onClick={handleMoreInfo}
          />
        </Box>
      )}
    </>
  )
})
