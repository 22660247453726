/* eslint-disable max-len */

import { ThemeOptions } from '@mui/material'

export const FONT_FAMILIES = {
  ROMAN: "'VNG-Roman', 'Avenir', Helvetica, Arial, sans-serif;",
  BOLD: "'VNG-Bold', 'Avenir', Helvetica, Arial, sans-serif;",
}

export const FONT_WEIGHTS = {
  ROMAN: 400,
  BOLD: 800,
}

export const TYPOGRAPHY: ThemeOptions['typography'] = {
  h1: {
    fontFamily: FONT_FAMILIES.BOLD,
    fontWeight: FONT_WEIGHTS.BOLD,
    fontSize: '2rem',
    lineHeight: 1.2,
    '@media (min-width:768px)': {
      fontSize: '2.25rem',
    },
  },
  h2: {
    fontFamily: FONT_FAMILIES.BOLD,
    fontWeight: FONT_WEIGHTS.BOLD,
    fontSize: '1.5rem',
    lineHeight: 1.2,
    '@media (min-width:768px)': {
      fontSize: '1.75rem',
    },
  },
  button: {
    fontFamily: FONT_FAMILIES.BOLD,
    fontWeight: FONT_WEIGHTS.BOLD,
    fontSize: '0.875rem',
    lineHeight: 1.75,
    textTransform: 'initial',
  },
  body1: {
    fontFamily: FONT_FAMILIES.ROMAN,
    fontWeight: FONT_WEIGHTS.ROMAN,
    fontSize: '1rem',
    lineHeight: 1.6,
    letterSpacing: 0,
  },
  body2: {
    fontFamily: FONT_FAMILIES.BOLD,
    fontWeight: FONT_WEIGHTS.BOLD,
    fontSize: '1rem',
    lineHeight: 1.6,
    letterSpacing: 0,
  },
  fontFamily: FONT_FAMILIES.ROMAN,
  htmlFontSize: 16,
  fontSize: 16,
  fontWeightMedium: FONT_WEIGHTS.ROMAN,
  fontWeightRegular: FONT_WEIGHTS.ROMAN,
  fontWeightBold: FONT_WEIGHTS.BOLD,
}
