interface IMatchSubstring {
  query: string
  string: string
  id: number
}

type MatchResult = {
  content: string
  match: boolean
}

type Description = {
  id: number
  description: string
}

export const matchSubstring = (
  query: IMatchSubstring['query'],
  string: IMatchSubstring['string'],
  { id, description }: Description,
): MatchResult => {
  if (!query) return { content: string, match: false }

  const doc = new DOMParser().parseFromString(string, 'text/html')
  const checkedTerms: string[] = []

  const markMatches = (node: Node) => {
    if (checkedTerms.includes(query)) return
    if (node.nodeType === Node.TEXT_NODE) {
      const text = node.textContent || ''
      const pattern = new RegExp(`\\b${query}\\b`, 'i')
      if (pattern.test(text)) {
        checkedTerms.push(query)
        const replacedText = text.replace(
          pattern,
          match =>
            `<mark tabindex="0" class="term-link" data-glossary-uid="${id}">${match}</mark>`,
        )
        const fragment = doc
          .createRange()
          .createContextualFragment(replacedText)
        if (node.parentNode) {
          node.parentNode.replaceChild(fragment, node)
        }
      }
    } else if (node.nodeType === Node.ELEMENT_NODE && node.nodeName !== 'A') {
      const children = node.childNodes
      for (let i = 0; i < children.length; i++) {
        markMatches(children[i])
      }
    }
  }

  if (doc && doc.body) {
    markMatches(doc.body)
    return { content: doc.body.innerHTML, match: true }
  }

  return { content: string, match: false }
}

export default matchSubstring
