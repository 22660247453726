import { useEffect, useState } from 'react'

export const useElViewportPosition = (ref: React.RefObject<HTMLElement>) => {
  const [left, setLeft] = useState(0)
  const [right, setRight] = useState(0)
  const [top, setTop] = useState(0)
  const [bottom, setBottom] = useState(0)

  useEffect(() => {
    if (ref.current) {
      setLeft(ref.current.getBoundingClientRect().left)
      setRight(ref.current.getBoundingClientRect().right)
      setTop(ref.current.getBoundingClientRect().top)
      setBottom(ref.current.getBoundingClientRect().bottom)
    } else if (process.env.NODE_ENV !== 'production') {
      console.warn(
        'useElViewportPosition: Cannot get element position, invalid ref supplied',
      )
    }
  }, [ref.current])

  return {
    left,
    right,
    top,
    bottom,
  }
}
