import { AcDefaultLayout, AcNoHeaderLayout } from '@layouts'
import { ContentView, HomeView, StylesView, TracksView } from '@views'

import { KEYS } from './keys.constants'

export const ROUTE_PATHS = {
  HOME: '/',
  TRACKS: '/tracks',
  CONTENT_PAGE: '/:trackId/:milestoneId',
  STYLES: '/styles',
}

export const ROUTES = [
  {
    id: KEYS.HOME,
    path: ROUTE_PATHS.HOME,
    element: <AcNoHeaderLayout />,
    children: [
      {
        index: true,
        element: <HomeView />,
      },
    ],
  },
  {
    path: ROUTE_PATHS.STYLES,
    element: <StylesView />,
  },
  {
    path: '/',
    element: <AcDefaultLayout />,
    children: [
      {
        id: KEYS.TRACKS,
        path: ROUTE_PATHS.TRACKS,
        layout: <AcDefaultLayout />,
        element: <TracksView />,
      },
      {
        path: ROUTE_PATHS.CONTENT_PAGE,
        layout: <AcDefaultLayout />,
        element: <ContentView />,
      },
    ],
  },
  /** [ ROUTES ]
   * You can add routes by doing:
   * `hygen make route`
   */
]
