import { ISingleAudience, ISingleTrack } from '@typings'

const AUDIENCES = '/audiences'
const MILESTONES = '/milestones'
const TRACKS = '/tracks'
const TRACK = '/track'
const TERMS = '/terms'
const PUBLIC = '/public'

export const ENDPOINTS = {
  MILESTONES: {
    SINGLE: (ID: ISingleTrack['id']) => `${PUBLIC}${TRACK}${MILESTONES}/${ID}`,
  },
  TRACKS: {
    ALL: `${PUBLIC}${TRACKS}`,
  },
  AUDIENCE: {
    SINGLE: (ID: ISingleAudience['id']) =>
      `${PUBLIC}${TRACK}${AUDIENCES}/${ID}`,
  },
  TERMS: {
    ALL: () => `${PUBLIC}${TERMS}`,
  },
}
