import { useEffect, useRef } from 'react'
import { Box, Link } from '@mui/material'

import { AcTypography } from '@components'
import { CONTACT_DRAWER } from '@constants'
import { usePiwik } from '@hooks/use-piwik'

// Styles
import styles from './ac-drawer-contact.module.scss'

export const AcDrawerContact = () => {
  const listRef = useRef<null | HTMLUListElement>(null)
  const { trackContactClick } = usePiwik()

  const handleClick = (e: any) => {
    if (e.target.id === 'phone-link') trackContactClick('phone')
    if (e.target.id === 'email-link') trackContactClick('email')
  }

  useEffect(() => {
    listRef.current?.addEventListener('click', handleClick)
    return () => listRef.current?.removeEventListener('click', handleClick)
  }, [])

  return (
    <Box>
      <Box sx={{ paddingBottom: 3, paddingTop: 6 }}>
        <AcTypography
          size="l"
          weight="bold"
          className={styles['title']}>
          {CONTACT_DRAWER.SECTION_ONE_TITLE}
        </AcTypography>
        <AcTypography>{CONTACT_DRAWER.SECTION_ONE_INTRO}</AcTypography>
        <ul>
          {CONTACT_DRAWER.SECTION_ONE_LIST.map(single => (
            <li>
              <AcTypography
                className={styles['list-item']}
                html>
                {single}
              </AcTypography>
            </li>
          ))}
        </ul>
        <AcTypography>{CONTACT_DRAWER.SECTION_ONE_OUTRO}</AcTypography>
        <AcTypography>{CONTACT_DRAWER.SECTION_ONE_FOOTER}</AcTypography>
      </Box>
      <Box>
        <AcTypography
          size="l"
          weight="bold"
          className={styles['title']}>
          {CONTACT_DRAWER.SECTION_TWO_TITLE}
        </AcTypography>
        <AcTypography>{CONTACT_DRAWER.SECTION_TWO_INTRO}</AcTypography>
        <ul ref={listRef}>
          {CONTACT_DRAWER.SECTION_TWO_LIST.map(single => (
            <li>
              <AcTypography
                className={styles['list-item']}
                html>
                {single}
              </AcTypography>
            </li>
          ))}
        </ul>
        <AcTypography>{CONTACT_DRAWER.SECTION_TWO_OUTRO}</AcTypography>
      </Box>
    </Box>
  )
}
