import { useEffect, useMemo, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Container } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { AcSelect } from '@components'
import { KEYS, LABELS, ROUTE_PATHS } from '@constants'
import { useIsMd } from '@hooks/use-isMd'
import { useStore } from '@hooks/use-store'
import { usePiwik } from '@hooks/use-piwik'
import { ISingleMilestone, ISingleTrack, ISingleTrackMilestone } from '@typings'

export const AcAppHeaderFilters = observer(() => {
  const { tracks } = useStore()
  const [milestoneOpen, setMilestoneOpen] = useState(false)
  const navigate = useNavigate()
  const isMd = useIsMd()
  const { trackTrackSelect, trackMilestoneSelect } = usePiwik()

  const openMilestoneDropdown = () => {
    return setMilestoneOpen(true)
  }

  const closeMilestoneDropdown = () => {
    return setMilestoneOpen(false)
  }

  const currentTrack = useMemo(
    () => tracks.all.find(({ id }) => id === tracks.current_selected_track_id),
    [tracks.current_selected_track_id],
  )

  const getCurrentMilestoneOptions = useMemo(() => {
    const arr: ISingleTrackMilestone[] = []
    if (!tracks.current_selected_track_id || !tracks.has_data) return arr

    if (!currentTrack) return arr

    const getCurrentMilestones =
      currentTrack &&
      currentTrack.rows.reduce((bulk, curr) => {
        for (const milestone of curr.milestones) {
          bulk.push(milestone)
        }
        return bulk
      }, arr)
    return getCurrentMilestones
  }, [tracks.current_selected_track_id, tracks.has_data])

  useEffect(() => {
    if (
      tracks.current_selected_milestone_id &&
      tracks.current_selected_track_id
    )
      navigate(
        generatePath(ROUTE_PATHS.CONTENT_PAGE, {
          trackId: tracks.current_selected_track_id,
          milestoneId: tracks.current_selected_milestone_id,
        }),
      )
  }, [tracks.current_selected_milestone_id])

  useEffect(() => {
    if (tracks.has_data) return
    tracks.getAll()
  }, [])

  return (
    <Container
      disableGutters={isMd}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: { xs: 2, md: 2 },
      }}>
      <AcSelect
        size="small"
        options={tracks.all}
        selectLabel={LABELS.TRACK}
        key={tracks.current_selected_track_id ?? 'A'}
        value={tracks.current_selected_track_id}
        valueKey={KEYS.ID}
        labelKey={KEYS.NAME}
        onChangeCallback={value => {
          openMilestoneDropdown()
          tracks.updateSelectedId(KEYS.TRACKS, value as number)
          trackTrackSelect(tracks.all.find(t => t.id === value))
        }}
      />
      <AcSelect
        size="small"
        open={milestoneOpen}
        onOpen={openMilestoneDropdown}
        onClose={closeMilestoneDropdown}
        options={getCurrentMilestoneOptions}
        selectLabel={LABELS.MILESTONE}
        key={tracks.current_selected_milestone_id ?? 'B'}
        value={tracks.current_selected_milestone_id}
        valueKey={KEYS.ID}
        labelKey={KEYS.NAME}
        onChangeCallback={value => {
          tracks.updateSelectedId(KEYS.MILESTONES, value as number)
          trackMilestoneSelect(
            currentTrack as ISingleTrack,
            getCurrentMilestoneOptions.find(
              ms => ms.id === value,
            ) as ISingleMilestone,
          )
        }}
      />
    </Container>
  )
})
