import { Alert, AlertProps } from '@mui/material'

interface IAcAlert extends AlertProps {
  label: string
}

export const AcAlert = ({ label, ...rest }: IAcAlert) => {
  return (
    <Alert
      variant="standard"
      {...rest}>
      {label}
    </Alert>
  )
}
