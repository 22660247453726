import { ThemeOptions } from '@mui/material'

export const BREAKPOINTS: ThemeOptions['breakpoints'] = {
  keys: ['sm', 'md', 'lg'],
  values: {
    xs: 0,
    sm: 0,
    md: 768,
    lg: 1200,
    xl: 1200,
  },
  unit: 'px',
}
