import { useMemo } from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material'

type IAcSelectOption = Record<string, string | number | null | any>

interface IAcSelect extends SelectProps {
  options: IAcSelectOption[]
  selectLabel: string
  onChangeCallback: (value: string | number) => void
  key: any
  value: any
  labelKey: string
  valueKey: string
  size?: SelectProps['size']
  readOnly?: boolean
  withEmpty?: boolean
  emptyLabel?: string
  sx?: SelectProps['sx']
}

export const AcSelect = ({
  options,
  selectLabel,
  withEmpty,
  emptyLabel,
  onChangeCallback,
  key,
  value,
  valueKey,
  size,
  labelKey,
  readOnly,
  sx,
  ...rest
}: IAcSelect) => {
  const handleChange = (newValue: any) => {
    if (onChangeCallback) onChangeCallback(newValue)
  }

  const generateInputLabel = useMemo(
    () => `select_${selectLabel}-${Math.random()}`,
    [selectLabel],
  )

  const generateSelectId = useMemo(
    () => `select_${selectLabel}-component-${Math.random()}`,
    [],
  )

  const readOnlyProps = useMemo(() => ({ readOnly }), [readOnly])

  return (
    <FormControl
      variant="outlined"
      size={size}
      sx={{ minWidth: { md: 130 }, width: '100%', ...sx }}>
      <InputLabel id={generateInputLabel}>{selectLabel}</InputLabel>
      <Select
        sx={sx}
        MenuProps={{
          disablePortal: true,
        }}
        disabled={!options || options.length === 0}
        key={key}
        labelId={generateInputLabel}
        id={generateSelectId}
        value={value}
        inputProps={readOnlyProps}
        onChange={e => handleChange(e.target.value)}
        label={selectLabel}
        {...rest}>
        {withEmpty && emptyLabel && (
          <MenuItem value={null as any}>{emptyLabel}</MenuItem>
        )}
        {options &&
          options.map(singleOption => (
            <MenuItem
              key={singleOption[valueKey]}
              value={singleOption[valueKey]}>
              {singleOption[labelKey]}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}
