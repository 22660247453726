import { useMemo } from 'react'
// Components
import { Alert, Box } from '@mui/material'
import type { ISingleContentTerm, ITab } from '@typings'
import { observer } from 'mobx-react-lite'

import { AcIcon, AcTabs, AcTypography } from '@components'
import { ICONS, LABELS } from '@constants'
import { usePiwik } from '@hooks/use-piwik'
import { useStore } from '@hooks/use-store'

// Styles
import styles from './ac-drawer-terms.module.scss'

interface TermsResultTabProps {
  terms: ISingleContentTerm[] | null
  searchValue?: string | null
}

const AcTermsResultTab = ({ terms, searchValue }: TermsResultTabProps) => {
  const searchResults: TermsResultTabProps['terms'] = useMemo(() => {
    if (!terms) return null
    if (!searchValue) return terms
    if (!searchValue || searchValue === '') return terms

    //Collection of result ID's
    const resultIdCollection: ISingleContentTerm['id'][] = []

    //Get Array of terms where searchvalue is in title
    const foundInTitle = terms?.filter(term =>
      term.name.toLowerCase().includes(searchValue.toLowerCase()),
    )

    //Loop through the items and push the item ID in the Array
    foundInTitle.map(({ id }) => resultIdCollection.push(id))

    //Get Array of terms where searchvalue is in description
    const foundInContent = terms.filter(term =>
      term.description.toLowerCase().includes(searchValue.toLowerCase()),
    )

    //Loop through the items and push the item ID in the Array
    foundInContent.map(({ id }) => resultIdCollection.push(id))

    //Create new set to filter out all double ID's
    const _setId = new Set(resultIdCollection)

    //Create an array of terms that match the ID's of the resultIdCollection
    const collection = Array.from(_setId).map((singleId: any) =>
      terms.find(singleTerm => singleTerm.id === singleId),
    )

    //Return the collection of terms
    return collection as TermsResultTabProps['terms']
  }, [searchValue, terms])

  return (
    <Box>
      {searchResults?.length ? (
        <Box
          mt={2}
          mb={2}>
          {searchResults.map((result, i: number) => {
            return (
              <Box
                key={i}
                mt={3}
                mb={3}>
                <AcTypography
                  weight="bold"
                  className={styles['result__title']}
                  mb={0.5}>
                  {result.name}
                </AcTypography>
                <AcTypography
                  html
                  mb={0}>
                  {result.description}
                </AcTypography>
              </Box>
            )
          })}
        </Box>
      ) : (
        <Box className={styles['no-results']}>
          <AcIcon
            icon={ICONS.SEARCH}
            size="xl"
          />
          <AcTypography
            weight="bold"
            className={styles['no-results-label']}>
            {LABELS.NO_RESULTS}
          </AcTypography>
        </Box>
      )}
    </Box>
  )
}

export const AcDrawerTerms = observer(() => {
  const { terms } = useStore()
  const { trackTermsSearchInput } = usePiwik()

  const generateTabs = useMemo(() => {
    let tabs: ITab[] = [
      {
        index: 1,
        title: LABELS.TERMS_GENERAL,
        content: (
          <AcTermsResultTab
            terms={terms.current_general_terms}
            searchValue={terms.searchValue}
          />
        ),
      },
    ]

    if (terms.current_page_terms && terms.current_page_terms.length) {
      tabs = [
        ...tabs,
        {
          index: 2,
          title: LABELS.PAGE_SPECIFIC,
          content: (
            <AcTermsResultTab
              terms={terms.current_page_terms}
              searchValue={terms.searchValue}
            />
          ),
        },
      ]
    }

    return tabs
  }, [terms.current_general_terms, terms.current_page_terms, terms.searchValue])

  if (terms.current_api_error) {
    return <Alert severity="error">{terms.current_api_error}</Alert>
  }

  const handleInputChange = (input: string | null) => {
    terms.setSearchTerm(input)
    trackTermsSearchInput(input)
  }

  return (
    <AcTabs
      withSearch
      ariaTitle={LABELS.TERMSLIST}
      tabs={generateTabs}
      onSearch={handleInputChange}
    />
  )
})
