/* eslint-disable max-len */
export const HEADER = {
  TITLE: 'De routekaart Financiële Zorgen waar u gemakkelijk informatie vindt',
  CONTENT:
    'Stap in de toekomst van effectieve schuldhulpverlening met de routekaart Financiële Zorgen, een hulpmiddel voor gemeenten om de dienstverlening aan inwoners met financiële problemen te optimaliseren en verbeteren. De routekaart Financiële Zorgen biedt u een basis voor de uitvoering van preventie, vroegsignalering, regelen van schulden, begeleiding en nazorg.',
  START_SEARCH: 'Start',
  EXPLAINER: 'Wat betekenen deze termen?',
}

export const BODY = {
  TITLE: 'Maak kennis met de routekaart',
  CARDS: [
    {
      ID: 1,
      TITLE: 'Sporen, mijlpalen, rollen en lagen',
      CONTENT:
        'De routekaart legt de mijlpalen vast die inwoners samen met u doorlopen, terwijl de inwoner altijd het centrale aandachtspunt blijft. Deze mijlpalen vormen samen het spoor. Per mijlpaal zijn er verschillende rollen uitgewerkt. Hierin wordt uitgelegd wie, wat kan doen, in de mijlpaal van uw keuze. Elke rol is onderverdeeld in verschillende lagen. Elke laag biedt toegang tot relevante documenten en ondersteunende producten.',
    },
    {
      ID: 2,
      TITLE: 'De rollen binnen de routekaart',
      CONTENT:
        'De ondersteunende rollen, zoals de jurist, beleidsmedewerker, informatiekundige of communicatiemedewerker, zijn cruciaal om de stappen en mijlpalen te realiseren. De informatiekundige laag beschrijft de benodigde informatievoorziening voor zowel de inwoner als de ondersteuner.',
    },
  ],
  ROLES: {
    TITLE: 'Elke rol is onderverdeeld in drie lagen',
    ITEMS: [
      {
        TITLE: '1. Het basisproces',
        CONTENT:
          'Dit is het gedeelte dat minimaal noodzakelijk is om in te richten. Hier worden per doelgroep, de wettelijke kaders benoemd en landelijke initiatieven die noodzakelijk zijn voor het basisproces. Met het basisproces kunnen gemeenten op een meer vergelijkbare basis werken en worden inwoners op een meer geharmoniseerde wijze ondersteund.',
      },
      {
        TITLE: '2. De inspiratielaag',
        CONTENT:
          'De laag met de doorontwikkeling van de huidige werkwijze waarin bestaande initiatieven beschreven voor de doorontwikkeling.',
      },
      {
        TITLE: '3. De toekomstlaag',
        CONTENT:
          'De laag waarin toekomst en de verwachte ontwikkelingen worden weergegeven. De laag waarin we durven te dromen en uiteraard het inwoners perspectief centraal staat.',
      },
    ],
  },
  FOOTER: {
    TITLE: 'Dat is nog niet alles!',
    CONTENT:
      'De routekaart biedt praktische tools om capaciteit en middelen optimaal te benutten, kennis te delen en op gelijke voet samen te werken. Hierdoor ontvangen inwoners in alle gemeenten uniforme ondersteuning op een vergelijkbare wijze. We streven naar een levendig platform waar u ervaringen kan delen, waarbij de contactgegevens van ervaren gemeenten anoniem op forum van de VNG gedeeld worden. Zo krijgen andere gemeenten toegang tot waardevolle informatie en ervaringen die al in praktijk zijn gebracht.',
  },
  CONTACT: {
    TITLE: 'Contact',
    CONTENT:
      'Heeft u ideeën, tips of informatie om op te nemen in de routekaart, dan zijn die van harte welkom. Laat een bericht achter op het VNG Fora maar mailen mag ook, naar <a href="mailto: schulden@vng.nl">schulden@vng.nl</a>.',
  },
  GO_TO_MAP: 'Ga naar routekaart',
}
