import { Outlet } from 'react-router-dom'

import { AcDrawer, AcScrollTop } from '@components'

export const AcNoHeaderLayout = () => {
  return (
    <>
      <AcScrollTop />
      <Outlet />
      <AcDrawer />
    </>
  )
}
