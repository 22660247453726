import { useEffect, useMemo, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
// Components
import { Box, Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'

import {
  AcButton,
  AcContainer,
  AcDrawerTerms,
  AcEmbed,
  AcPageWrapper,
  AcRichText,
  AcSelect,
  AcTitle,
  AcTypography,
} from '@components'
// Content
import { BODY, HEADER, KEYS, LABELS, ROUTE_PATHS } from '@constants'
import { useIsMd } from '@hooks/use-isMd'
import { useStore } from '@hooks/use-store'
import { ISingleTrackMilestone } from '@typings'

// Styles
import styles from './home-view.module.scss'

export const HomeView = observer(() => {
  const { drawer, tracks } = useStore()
  const navigate = useNavigate()
  const isMd = useIsMd()
  const [openMilestoneDropdown, setOpenMilestoneDropdown] = useState(false)
  const [allowSearch, setAllowSearch] = useState(false)

  const handleOpenMilestoneDropdown = () => setOpenMilestoneDropdown(true)
  const handleCloseMilestoneDropdown = () => setOpenMilestoneDropdown(false)

  const getCurrentMilestoneOptions = useMemo(() => {
    const arr: ISingleTrackMilestone[] = []
    if (!tracks.current_selected_track_id || !tracks.has_data) return arr

    const currentTrack = tracks.all.find(
      ({ id }) => id === tracks.current_selected_track_id,
    )

    if (!currentTrack) return arr

    const getCurrentMilestones =
      currentTrack &&
      currentTrack.rows.reduce((bulk, curr) => {
        for (const milestone of curr.milestones) {
          bulk.push(milestone)
        }
        return bulk
      }, arr)

    return getCurrentMilestones
  }, [tracks.current_selected_track_id])

  useEffect(() => {
    if (
      tracks.current_selected_milestone_id &&
      tracks.current_selected_track_id
    ) {
      setAllowSearch(true)
    } else {
      setAllowSearch(false)
    }
  }, [tracks.current_selected_milestone_id])

  useEffect(() => {
    if (tracks.has_data) return
    tracks.getAll()
  }, [])

  // Methods
  const handleSearch = () => {
    if (allowSearch)
      navigate(
        generatePath(ROUTE_PATHS.CONTENT_PAGE, {
          trackId: tracks.current_selected_track_id,
          milestoneId: tracks.current_selected_milestone_id,
        }),
      )
  }

  return (
    <AcPageWrapper>
      <Box className={styles['header']}>
        <AcContainer maxWidth={822}>
          <AcTitle
            level={1}
            className={styles['header__title']}>
            {HEADER.TITLE}
          </AcTitle>
          <AcTypography className={styles['header__intro']}>
            {HEADER.CONTENT}
          </AcTypography>
          <Grid
            container
            spacing={1.5}
            mt={3.5}>
            <Grid
              item
              sm={12}
              md={5}>
              <AcSelect
                options={tracks.all}
                selectLabel={LABELS.TRACK}
                labelKey={KEYS.NAME}
                valueKey={KEYS.ID}
                readOnly={tracks.all.length === 1}
                sx={({ palette }) => ({
                  backgroundColor: palette.common.white,
                })}
                onChangeCallback={e => {
                  isMd && handleOpenMilestoneDropdown()
                  tracks.updateSelectedId(KEYS.TRACKS, e as number)
                }}
                value={tracks.current_selected_track_id}
                key={tracks.current_selected_track_id ?? 'A'}
              />
              {tracks.all.length === 1 && (
                <div style={{ marginTop: 8 }}>
                  <AcTypography size="s">
                    {LABELS.WORKING_ON_NEW_CONTENT}
                  </AcTypography>
                </div>
              )}
            </Grid>
            <Grid
              item
              sm={12}
              md={5}>
              <AcSelect
                open={openMilestoneDropdown}
                onOpen={handleOpenMilestoneDropdown}
                onClose={handleCloseMilestoneDropdown}
                selectLabel={LABELS.MILESTONE}
                sx={({ palette }) => ({
                  backgroundColor: palette.common.white,
                })}
                options={getCurrentMilestoneOptions}
                key={tracks.current_selected_milestone_id ?? 'B'}
                labelKey={KEYS.NAME}
                valueKey={KEYS.ID}
                value={tracks.current_selected_milestone_id}
                onChangeCallback={e =>
                  tracks.updateSelectedId(KEYS.MILESTONES, e as number)
                }
              />
            </Grid>
            <Grid
              item
              sm={12}
              md={2}>
              <AcButton
                label={HEADER.START_SEARCH}
                disabled={!allowSearch}
                onClick={handleSearch}
                fullWidth
              />
            </Grid>
          </Grid>
        </AcContainer>
      </Box>
      <Box className={styles['body']}>
        <AcContainer maxWidth={822}>
          <Grid
            container
            spacing={3}>
            <Grid item>
              <AcTitle
                level={2}
                className={styles['body__title']}>
                {BODY.TITLE}
              </AcTitle>
            </Grid>
            {BODY.CARDS.map(CARD => (
              <Grid
                key={CARD.ID}
                item
                xs={12}
                md={6}>
                <AcTypography
                  className={styles['card__title']}
                  weight="bold"
                  size="l"
                  mb={0.5}>
                  {CARD.TITLE}
                </AcTypography>
                <AcTypography>{CARD.CONTENT}</AcTypography>
              </Grid>
            ))}
            <Grid
              item
              xs={12}
              md={12}
              mb={-3}>
              <AcTypography
                size="l"
                weight="bold"
                className={styles['body__title']}>
                {BODY.ROLES.TITLE}
              </AcTypography>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}>
              {BODY.ROLES.ITEMS.map(SingleItem => (
                <AcTypography
                  mb={2}
                  html>{`<b>${SingleItem.TITLE}: </b>${SingleItem.CONTENT}`}</AcTypography>
              ))}
            </Grid>
          </Grid>
          <Box
            mt={5}
            mb={6}>
            <AcEmbed
              type="video"
              src="https://www.youtube.com/embed/SaVxufBRhEs?si=lDWNYH6dhzoEU8Vn&amp;controls=0"
              title="De routekaart Financiële Zorgen"
              className={styles['youtube-embed']}
            />
          </Box>
          <Box mb={4}>
            <AcTitle
              level={2}
              className={styles['body__title']}>
              {BODY.FOOTER.TITLE}
            </AcTitle>
            <AcTypography>{BODY.FOOTER.CONTENT}</AcTypography>
          </Box>
          <Box mb={4}>
            <AcTitle
              level={2}
              className={styles['body__title']}>
              {BODY.CONTACT.TITLE}
            </AcTitle>
            <AcRichText content={BODY.CONTACT.CONTENT} />
          </Box>
          <AcButton
            label={BODY.GO_TO_MAP}
            onClick={() => navigate(ROUTE_PATHS.TRACKS)}
          />
        </AcContainer>
      </Box>
    </AcPageWrapper>
  )
})
