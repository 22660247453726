import { AudienceService } from './audience.services'
import { MilestonesService } from './milestones.services'
import { TracksService } from './tracks.services'
import { TermsService } from './terms.services'

export default () => ({
  tracks: new TracksService(),
  milestones: new MilestonesService(),
  audiences: new AudienceService(),
  terms: new TermsService(),
})
