import { AcTrackRow, AcTypography, AcTrackSection } from '@components'
import { Box } from '@mui/material'

import { ISingleTrack } from '@typings'
import styles from './ac-track.module.scss'

interface IAcTrack {
  track: ISingleTrack
}

export const AcTrack = ({ track }: IAcTrack) => {
  return (
    <Box
      className={styles['track']}
      aria-label={track.name}
      style={
        {
          '--track-color': track.color,
        } as React.CSSProperties
      }>
      <Box
        bgcolor={track.color}
        className={styles['track-label']}>
        <AcTypography
          weight="bold"
          size="s"
          className={styles['track-title']}>
          {track.name}
        </AcTypography>
      </Box>
      {track.rows.map((row, rowCount) => {
        const previousRow = track.rows[rowCount - 1] || null
        const nextRow = track.rows[rowCount + 1] || null
        const previousRows = track.rows.slice(0, rowCount)
        const nextRows = track.rows.slice(rowCount + 1, track.rows.length)
        const hasPreviousMilestones =
          previousRows.filter(row => row.milestones.length)?.length > 0
        const hasAdditionalMilestones =
          nextRows.filter(row => row.milestones.length)?.length > 0

        return (
          <>
            <AcTrackRow
              key={row.id}
              track={track}
              currentRow={row}
              currentRowIndex={rowCount}
              previousRow={previousRow}
              nextRow={nextRow}
              hasPreviousMilestones={hasPreviousMilestones}
              hasAdditionalMilestones={hasAdditionalMilestones}
              isFirstRow={!hasPreviousMilestones && row.milestones.length > 0}
              isLastRow={!hasAdditionalMilestones && row.milestones.length > 0}
            />
            {row.label && hasAdditionalMilestones && (
              <AcTrackSection
                is_relevant={row.label.is_relevant}
                label={row.label.label}
                color={track.color}
              />
            )}
          </>
        )
      })}
    </Box>
  )
}
