import { CircularProgress } from '@mui/material'

import styles from './ac-loader.module.scss'

export const AcLoader = () => {
  return (
    <div className={styles['ac-loader-wrapper']}>
      <CircularProgress />
    </div>
  )
}
