import { useMemo } from 'react'
import clsx from 'clsx'

import { AcLoader } from '../index.core.components'

import styles from './ac-content-container.module.scss'

interface IAcContentContainer {
  loading?: boolean
  children: React.ReactNode
}

export const AcContentContainer = ({
  loading,
  children,
}: IAcContentContainer) => {
  const contentClasses = useMemo(
    () =>
      clsx(
        styles['ac-content-container'],
        loading && styles['ac-content-container--loading'],
      ),
    [loading],
  )

  return (
    <section className={contentClasses}>
      {loading ? <AcLoader /> : children}
    </section>
  )
}
