import { action, computed, makeAutoObservable } from 'mobx'

import { KEYS, STORE_KEYS } from '@constants'
import { Store } from '@stores'
import { ISingleMilestone, ISingleMilestoneAudience } from '@typings'

export class MilestonesStore {
  store: Store
  single: ISingleMilestone | null
  selected: ISingleMilestone['id'] | null
  loading: boolean

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.loading = false
    this.selected = null
    this.single = null
  }

  @computed
  get has_data(): boolean {
    return !!this.single && this.single !== null
  }

  @computed
  get first_audience_id(): ISingleMilestoneAudience['id'] | null {
    return this.current_audiences.length ? this.current_audiences[0]?.id : null
  }

  @computed
  get current_audiences(): ISingleMilestone['audiences'] {
    return this.single ? this.single.audiences : []
  }

  @action
  getSingle = async (id: ISingleMilestone['id']) => {
    this.store.set(STORE_KEYS.MILESTONES, KEYS.LOADING, true)
    this.store.set(STORE_KEYS.AUDIENCES, KEYS.LOADING, true)
    try {
      const single = await this.store.services.milestones.getSingle(id)
      this.store.set(STORE_KEYS.MILESTONES, KEYS.SINGLE, single)

      if (!single.audiences.length) {
        this.store.audiences.setEmpty()
        this.store.set(STORE_KEYS.TERMS, KEYS.PAGE_TERMS, null)
      } else if (single.audiences[0].id) {
        this.store.audiences.getSingle(single.audiences[0].id)
      }

      this.store.tracks.updateSelectedId(KEYS.MILESTONES, id)
    } catch (e) {
      console.error(e)
    } finally {
      this.store.set(STORE_KEYS.MILESTONES, KEYS.LOADING, false)
    }
  }
}
