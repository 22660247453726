import { useMemo } from 'react'
import clsx from 'clsx'
import { AcButton } from '../index.core.components'
import { ISingleTrackSection } from '@typings'
import styles from './ac-track-section.module.scss'

interface IAcTrackSection extends ISingleTrackSection {
  color: string
}

export const AcTrackSection = ({
  is_relevant,
  label,
  color,
}: IAcTrackSection) => {
  const sectionClasses = useMemo(
    () =>
      clsx(
        styles['ac-track-section'],
        !is_relevant && styles['ac-track-section--hide'],
      ),
    [is_relevant],
  )

  return (
    <AcButton
      variant="text"
      label={is_relevant ? label || '' : ''}
      tabIndex={-1}
      className={sectionClasses}
      style={
        {
          '--track-color': color,
          '--button-height': '56px',
          '--row-gap': '16px',
        } as React.CSSProperties
      }
    />
  )
}
